import {Component, Injector, ViewEncapsulation, SimpleChanges, Input, OnChanges, Output} from '@angular/core';
import {GeneralPortfolioComponent} from "../general-portfolio/general-portfolio.component";
import {ColumnType} from "../../components/cim-datatable/cim-datatable.component";
import {CompanyModel} from "../../shared/models/company.model";
import { Subscription } from 'rxjs';
import { forkJoin, of } from 'rxjs';

enum EfficientType {
  Constrainted = 'optim_efficient_frontier',
  Unconstrainted = 'optim_efficient_frontier_unconstrained'
}

@Component({
  selector: 'app-optimization',
  templateUrl: './optimization.component.html',
  styleUrls: [
    '../overview/portfolio-summary/summary-cards/summary-cards.component.scss',
    './optimization.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class OptimizationComponent extends GeneralPortfolioComponent {
  isPrint = false;
  efficientFrontierType = EfficientType.Constrainted;


  @Input() optim : Subscription;
  tickers = [];
  correlationMatrixData:any = null;
  covarianceMatrixData:any = null;
  correlationMatrixTableData:any = null;
  covarianceMatrixTableData:any = null;
  covarianceMatrixBlacklitterTableData:any = null;

  evaluationData:any = [];
  optimPerformance: any[] = [];
  optimWeights: any[] = [];
  filteredOptimWeights: any[] = [];

  dataLoaded = false;
  viewTablesLoaded = false;

  filteredForecastedReturnsTableData: any[] = [];

  top3Holdings: any[] = [];
  top3LargestDev: any[] = [];

  minVolatility: any = 0;
  maxVolatility: any = 100;

  volatilityLine: any = 0;
   lineLabels = {
        efficient_portfolio_max_sharpe_ratio: 'Max Sharpe PF',
        efficient_portfolio_given_mu: 'Target Return PF',
        efficient_portfolio_given_sigma: 'Target Risk PF',
        baseline_portfolio: 'Baseline PF',
        efficient_portfolio_max_mu: 'Maximum Return',
        minimum_variance_portfolio: 'Minimum Risk'
    };

  // riskContributionData: any = {
  //   categories: [],
  //   series: [
  //     {
  //       data: [],
  //       name: 'Risk',
  //       type: 'column'
  //     },
  //     {
  //       data: [],
  //       name: 'Weight',
  //       type: 'column'
  //     }
  //   ]
  // };

  riskContributionData: any[] = [];
  evaluationDataLoaded: boolean = false;
  marginalRiskContributionData: any[] = [{data:[]}];


  forecastedReturnsTableColumns: any[] = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'weight_marketcap', label: 'Marketcap Weight', type: ColumnType.Percentage},
    // {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple, inputDisabled: true, canHide: true},
    {id: 'volatility', label: 'Volatility', type: ColumnType.Percentage},
    {id: 'historical_returns', label: 'Historical Returns', type: ColumnType.Percentage},
    /*{id: 'implied_returns', label: 'Implied Return', type: ColumnType.Number2Decimal, suffix: '%'},*/
    {id: 'market_equilibrium', label: 'Market Equilibrium Return', type: ColumnType.Percentage},
    // {id: 'forecasted_returns', label: 'Forecasted Return', type: ColumnType.Input, suffix: '%'},
    {id: 'implied_returns', label: 'Implied returns', type: ColumnType.Percentage},
    {id: 'mkt_eq_returns', label: 'Implied vs Mkt. Eq. Returns', type: ColumnType.Percentage},
  ];

  footerData:any = {
    company_name: 'Total expected return',
    Ticker: '',
    Weight: '',
    weight_marketcap: '',
    volatility: '',
    historical_returns: 'foot',
    market_equilibrium: 'foot',
    /*implied_returns: 'foot',*/
    forecasted_returns: 'foot',
  }

  filter: any = {
    sector: null,
    country: null,
    type: null
  }

  filterLists: any = {
    sectorList: [],
    countryList: [],
    typeList: [],
  }

  companyReturns: any = {};

  historicalReturnTableData: any[] = [];
  filteredHistoricalReturnTableData: any[] = [];
  historicalReturnTableColumns: any[] = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'volatility', label: 'Volatility', type: ColumnType.Percentage},
    {id: 'historical_returns', label: 'Historical Returns', type: ColumnType.Percentage},
    {id: 'implied_returns', label: 'Implied Return', type: ColumnType.Percentage},
    {id: 'market_equilibrium', label: 'Market Equilibrium Returns', type: ColumnType.Percentage},
    {id: 'forecasted_returns', label: 'Combined Returns', type: ColumnType.Percentage, bold: true},
  ];

  marketEquilibriumTableData: any[] = [];
  filteredMarketEquilibriumTableData: any[] = [];
  marketEquilibriumTableColumns: any[] = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'weight_marketcap', label: 'Marketcap Weight', type: ColumnType.Percentage},
    {id: 'implied_returns', label: 'Market Equilibrium Return', type: ColumnType.Percentage},
  ];

  forecastedReturnsTableData: any[] = [];

  resultsTableData: any[] = [];
  resultsTableDataFooter: any[] = [];
  resultsTableColumns: any[] = [
    {id: 'companyName', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'expectedReturn', label: 'Expected return', type: ColumnType.Percentage},
    {id: 'historicalReturn', label: 'Historical return', type: ColumnType.Percentage},
    {id: 'volatility', label: 'Volatility', type: ColumnType.Percentage},
    {id: 'sharpe', label: 'Expected Sharpe', type: ColumnType.Number2Decimal},
    {id: 'allocation', label: 'Allocation', type: ColumnType.Percentage},
    {id: 'minWeight', label: 'Min weight', type: ColumnType.Percentage},
    {id: 'maxWeight', label: 'Max weight', type: ColumnType.Percentage},
  ];


  optimizedPortfolioWeightsColumns = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple},
    {id: 'minimum_variance_portfolio', label: 'Minimum Variance Portfolio', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_max_mu', label: 'Efficient Portfolio Max Return', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_max_sharpe_ratio', label: 'Efficient Portfolio Max Sharpe', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_given_mu', label: 'Efficient Portfolio Target Return', type: ColumnType.Percentage},
    {id: 'efficient_portfolio_given_sigma', label: 'Efficient Portfolio Target Volatility', type: ColumnType.Percentage},
  ];
  constraintLists = {
    assets: [],
    sectors: [],
    countries: []
  }

  impliedReturnColumns = [
    {id: 'company_name', label: 'Company', type: ColumnType.CompanyInfo},
    {id: 'Ticker', label: 'Ticker', type: ColumnType.Simple},
    {id: 'Weight', label: 'Weight', type: ColumnType.Percentage},
    {id: 'implied_ret', label: 'Implied Return', type: ColumnType.Percentage},
  ];
  impliedReturnData = [];
  filteredImpliedReturnData = [];
  portfolioImpliedReturn = 0;
  portfolioMarketEquilibriumReturn = 0;

  viewReturns: any = {}

  efficientOptimWeights:any[] = [];
  typeList = [];
  portfolioHasETF = false;

  selectedPerformanceType = '';

  constructor(injector: Injector) {
    super(injector);
    document.addEventListener("scroll", (event) => {
      this.getClosestCard();
    });

    this.service.optim$.subscribe(optim => this.reloadPf(optim));
  }

  reloadPf(optim){
        this.optim = optim;
    
    
    if(this.portfolio.symbol_performance){

      this.setGeneralData();
      
    }

    if(this.riskContributionData.length > 0){
      this.initViewTables();
    }
    this.initEfficientChart(); 
  }
  setGeneralData() {
    this.optim = this.optim.toString() !== '' ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;
    this.optimName = this.lineLabels[this.optim.toString()];

    this.riskContributionData = [];
    this.marginalRiskContributionData = [{data:[]}];
    this.portfolio.symbol_performance.map((e: any) => {
      if (e.Ticker !== 'Portfolio') {
        let evalData = this.evaluationData.risk_contribution.find(a => a.Ticker == e.Ticker && a.Portfolio == this.optim.toString());
        let forecastedReturns = this.optimizationData.forecasted_returns.find(a => a.Ticker == e.Ticker).forecasted_returns;
        if (evalData) {
          this.riskContributionData.push({
            Ticker: this.getFundName(e.Ticker),
            riskContribution: evalData.risk_contribution*100,
            Weight: evalData.weight*100,
            Return: forecastedReturns
          });
          this.marginalRiskContributionData[0].data.push({
            company: e.reference_table && e.reference_table.name ? e.reference_table.name.substring(0, 10) + '...': this.getFundName(e.Ticker),
            risk: parseFloat(((evalData.weight * evalData.risk_contribution)*100).toFixed(2)),
            return: parseFloat(((evalData.weight * forecastedReturns)*100).toFixed(2)),
          });
        }
      }
    });

  }

 
  getClosestCard() {
    const cards: any = document.getElementsByClassName('settings');
    const navs = document.getElementsByClassName('under-container')[2].children;
    let closest = 0;
    let selected = cards[0];
    for(let counter = 0; counter < cards.length; counter++) {
      if(window.scrollY > cards[counter].offsetTop) {
        selected = cards[counter];
        closest = counter;
      }
      if(navs[counter]) {
        navs[counter].className = '';
      }
    }
    navs[closest].className = 'active';
  }

  initPage() {
    this.typeList = this.service.typeList;
    this.portfolioHasETF = this.portfolio.hasETF();
    
    // need the performance data for efficient frontier
    if (this.portfolio && this.portfolio.id) {
      if (!this.optimizationData) {

          this.fetchOptimizationData()

      }
      this.optim ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;
      if (!this.plotData) {
        this.fetchPlotData()

      }
      


    }
    this.renameEvaluationTypesInPerformance();
    this.initGroupConstraintLists();
    
  }
  async fetchPlotData(){


      const results = forkJoin({
         a : await this.fOptimizationData(),
         c : await this.service.fetchPerformancePlot(this.portfolio.id),
         v : await this.service.fetchEvaluationData(this.portfolio.id, true),

        // a : of('').pipe(delay(1500)) 
      }).subscribe(

        val => {
          this.optimizationData = val.a;
          this.evaluationData = val.v;
          this.plotData = this.convertToPercentages(val.c);
          

          this.reloadPf(this.optim ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance);
        });
      this.evaluationDataLoaded = true;

  }

  async fOptimizationData(){
    return this.service.fetchOptimizationData(this.portfolio.id);
  }
  beforeViewTablesLoaded() {
    this.footerData.historical_returns = `${this.viewReturns.historical || 0}%`;
    this.footerData.implied_returns = `${this.viewReturns.implied || 0}%`;
    this.footerData.market_equilibrium = `${this.viewReturns.market || 0}%`;
    this.footerData.forecasted_returns = `${this.viewReturns.forecast || 0}%`;

    this.initTableFilters();
  }

  initTableFilters() {
    this.filteredForecastedReturnsTableData = this.forecastedReturnsTableData.map((e) => {
      return {
        ...e,
        mkt_eq_returns: (e.implied_returns - e.market_equilibrium).toPrecision(4),
      }
    });;
    this.filter = {
      sector: null,
      country: null,
      type: null
    }

    let sectors = [];
    let country = [];
    let type = [];

    this.forecastedReturnsTableData.map(d => {
      sectors.push(d.sector);
      country.push(d.country);
      type.push(d.type);
    })

    this.filterLists = {
      sectorList: [...new Set(sectors)],
      countryList: [...new Set(country)],
      typeList: [...new Set(type)],
    }
  }

  reloadHistoricalTable() {
    this.viewTablesLoaded = false;
    this.historicalReturnTableData = [];
    this.marketEquilibriumTableData = [];
    this.forecastedReturnsTableData = [];
    this.impliedReturnData = [];
    this.service.fetchOptimizationData(this.portfolio.id, true).subscribe((resp: any) => {
      this.optimizationData = resp;
      this.initViewTables();
    })
  }

  onWebsocketSuccess(msg: any) {
    if (msg.task && msg.task == 'covmat') {
      this.getCorrelationMatrix()
      this.getCovarianceMatrix()
    } else {
      this.service.notificationService.open('Optimization calculation finished. Reloading...')
      window.location.reload();
    }
  }


  extendListWithCompanyData(list = [], name, returnName) {
    let result = [];
    list.map((d: any) => {
      d.return = +(d[returnName]*100).toFixed(2);

      if (d.Ticker === 'Portfolio') {
        this.viewReturns[name] = d.return;
      } else {
        
        if (!this.companyReturns[d.Ticker]) {
          this.companyReturns[d.Ticker] = {}
        }
        this.companyReturns[d.Ticker][name] = d.return;
        d.company_name = this.portfolio.security_overviews[d.Ticker] ? this.portfolio.security_overviews[d.Ticker].company_name : this.getFundName(d.Ticker);
        result.push(d);
      }
    })
    return result
  }



  initViewTables() {
    this.viewTablesLoaded = false;
    this.companyReturns = {};
     this.resultsTableData= [];
     this.allocation=[];

    let baselinePorfio = {}; 

    this.optimPerformance.push(baselinePorfio);
    var optim = this.optim ? this.optim.toString() : this.optimizationData.optim_parameters.optim_performance;
    for(let prop of ['optim_performance', "optim_performance_unconstrained", "optim_performance_cost_of_constrained"]){
          this.optimPerformance[prop] = this.portfolio[prop].filter(d => d.view_name === 'forecasted_returns' && d.Portfolio === optim) ;

    }

    if(optim == 'baseline_portfolio'){
      
       baselinePorfio = {
        'Expected_Return' : this.optimizationData.forecasted_returns.filter(d => d.Ticker == 'Portfolio')[0].forecasted_returns,
        'Std_Deviation' : this.optimizationData.historical_returns.filter(d => d.Ticker == 'Portfolio')[0].volatility,
        'Sharpe_Ratio': 1,
        'view_name': 'forecasted_returns',
        'Portfolio': 'baseline_portfolio'

      }
      this.optimPerformance['optim_performance'].push( baselinePorfio);
    }

    this.historicalReturnTableData = this.extendListWithCompanyData(this.optimizationData.historical_returns, 'historical', 'historical_returns');
    this.marketEquilibriumTableData = this.extendListWithCompanyData(this.optimizationData.market_equilibrium, 'market', 'implied_ret');
    this.forecastedReturnsTableData = this.extendListWithCompanyData(this.optimizationData.forecasted_returns, 'forecast', 'forecasted_returns');
    this.impliedReturnData = this.extendListWithCompanyData(this.optimizationData.implied_returns, 'implied', 'implied_ret');

    this.extendListWithCompanyData(this.optimizationData.historical_returns, 'volatility', 'volatility');
    this.extendListWithCompanyData(this.optimizationData.market_equilibrium, 'weight_marketcap', 'weight_marketcap');

    this.forecastedReturnsTableData = this.forecastedReturnsTableData.map((d: any) => {
      if (!d.converted) {
        d.forecasted_returns = +(d.forecasted_returns)// multiple with 100 to get percenteges in input and table
        d.historical_returns = this.companyReturns[d.Ticker]['historical']/100;
        d.volatility = this.companyReturns[d.Ticker]['volatility']/100;
        d.weight_marketcap = this.companyReturns[d.Ticker]['weight_marketcap']/100;
        d.market_equilibrium = this.companyReturns[d.Ticker]['market']/100;
        d.implied_returns = this.companyReturns[d.Ticker]['implied']/100;
        d.sector = this.portfolio.security_overviews[d.Ticker] ? this.portfolio.security_overviews[d.Ticker].sector.name : '';
        d.country = this.portfolio.security_overviews[d.Ticker] ? this.portfolio.security_overviews[d.Ticker].country.name : '';
        d.type = this.portfolio.security_overviews[d.Ticker] ? this.portfolio.security_overviews[d.Ticker].security_type_equity : '';
        d.converted = true;
        d.allocation = this.optimWeights.map((v) => {

          if (v.Ticker == d.Ticker && v.view_name == 'forecasted_returns') {
            if(optim === 'efficient_portfolio_max_sharpe_ratio')
              return v.efficient_portfolio_max_sharpe_ratio;
            if(optim === 'efficient_portfolio_max_mu')
              return v.efficient_portfolio_max_mu;
            if(optim == 'minimum_variance_portfolio')
              return v.minimum_variance_portfolio;
            if(optim === 'efficient_portfolio_given_mu')
              return v.efficient_portfolio_given_mu
            if(optim === 'efficient_portfolio_given_sigma')
              return v.efficient_portfolio_given_sigma
            if(optim === 'baseline_portfolio')
              return this.evaluationData.risk_contribution.find(a => a.Ticker == d.Ticker && a.Portfolio == optim).weight
          } else {
            return false;
          }
        }).filter((e) => {
          return e !== false;
        })[0]
      }
      return d
    });
    this.filteredHistoricalReturnTableData = this.historicalReturnTableData.map((e) => {
      const ticker = this.forecastedReturnsTableData.filter((d) => d.Ticker === e.Ticker)[0];
      return {
        ...ticker,
        ...e,
        implied_returns: ticker.implied_returns ,
        market_equilibrium: ticker.market_equilibrium ,
        forecasted_returns: ticker.forecasted_returns ,
        mkt_eq_returns: (e.implied_returns - e.market_equilibrium)/100
      }
    });
    this.resultsTableData = [];
    this.resultsTableDataFooter = [];
    this.optimizationData.forecasted_returns.map((data: any, index) => {
      let companyName = this.portfolio.security_overviews[data.Ticker] ? this.portfolio.security_overviews[data.Ticker].company_name : this.getFundName(data.Ticker);
      let constraintData = this.getConstraintForAsset(companyName);
      if(data.Ticker === 'Portfolio'){
        this.volatilityLine = (this.optimPerformance['optim_performance'][0].Std_Deviation*100).toFixed(2);
        this.resultsTableDataFooter.push({
          companyName: companyName,
          expectedReturn: (this.optimPerformance['optim_performance'][0].Expected_Return*100).toFixed(2) + "%",
          historicalReturn: (this.optimizationData.historical_returns[index].historical_returns*100).toFixed(2) + "%",
          volatility: (this.optimPerformance['optim_performance'][0].Std_Deviation*100).toFixed(2) + "%",
          sharpe: (this.optimPerformance['optim_performance'][0].Sharpe_Ratio).toFixed(2),
          allocation: "100%"
        });
      }else{
        var allocation =  this.optimWeights.map((v) => {

            if (v.Ticker == data.Ticker && v.view_name == 'forecasted_returns') {
               if(optim === 'efficient_portfolio_max_sharpe_ratio')
                 return v.efficient_portfolio_max_sharpe_ratio;
               if(optim === 'efficient_portfolio_max_mu')
                 return v.efficient_portfolio_max_mu;
               if(optim == 'minimum_variance_portfolio')
                 return v.minimum_variance_portfolio;
               if(optim === 'efficient_portfolio_given_mu')
                 return v.efficient_portfolio_given_mu
               if(optim === 'efficient_portfolio_given_sigma')
                 return v.efficient_portfolio_given_sigma
               if(optim === 'baseline_portfolio')
                 return this.evaluationData.risk_contribution.find(a => a.Ticker == data.Ticker && a.Portfolio == optim).weight
            } else {
              return false;
            }
          }).filter((e) => {
            return e !== false;
          });
        this.allocation.push({'Ticker': data.Ticker, 'company_name': companyName, 'weight' : (allocation[0]*100).toFixed(2)});
        this.resultsTableData.push({
          companyName: companyName,
          Ticker: data.Ticker,
          expectedReturn: (data.forecasted_returns),
          historicalReturn: this.optimizationData.historical_returns[index].historical_returns,
          volatility: this.optimizationData.historical_returns[index].volatility,
          sharpe: ((data.forecasted_returns)/this.optimizationData.historical_returns[index].volatility).toFixed(2),
          allocation: allocation,

          maxWeight: constraintData ? constraintData.max : 0,
          minWeight: constraintData ? constraintData.min : 0
        });
      }

    })
    this.filteredMarketEquilibriumTableData = [...this.marketEquilibriumTableData];
    this.filteredForecastedReturnsTableData = this.forecastedReturnsTableData.map((e) => {
      return {
        ...e,
        mkt_eq_returns: (e.implied_returns - e.market_equilibrium).toPrecision(4),
      }
    });
    this.filteredImpliedReturnData = [...this.impliedReturnData];

    this.optimizationData.market_equilibrium.map(d => {
      if (d.Ticker == 'Market Equilibrium') {
        this.portfolioMarketEquilibriumReturn = +(d.implied_ret*100).toFixed(2);
      }
    });
    if(this.filteredForecastedReturnsTableData[0].weight == this.filteredForecastedReturnsTableData[1].weight == this.filteredForecastedReturnsTableData[2].weight){
      Object.assign(this.top3Holdings, this.filteredForecastedReturnsTableData.sort((a, b) => {return parseFloat(b.weight_marketcap) - parseFloat(a.weight_marketcap)}));
    }else{
      Object.assign(this.top3Holdings, this.filteredForecastedReturnsTableData.sort((a, b) => {return parseFloat(b.Weight) - parseFloat(a.Weight)}));
    }    Object.assign(this.top3LargestDev = this.filteredForecastedReturnsTableData.sort((a, b) => {return Math.abs(+b.mkt_eq_returns) - Math.abs(+a.mkt_eq_returns)}));
    this.onForecastedReturnInputChange();
    this.beforeViewTablesLoaded();



    

    this.viewTablesLoaded = true;
  }

  onForecastedReturnInputChange() {
    let portfolioReturn = 0;
    this.forecastedReturnsTableData.map(d => {
      portfolioReturn += d.Weight * d.forecasted_returns
    })
    this.viewReturns.forecast = +(portfolioReturn.toFixed(2));
  }

  saveInputsAndTriggerHistoricalCalc() {
    let optimParams: any = this.collectParamsForPost();
    this.service.saveOptimizationData(this.portfolio.id, optimParams).subscribe((resp: any) => {
      if (resp.errro) {
        this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open("Optimization parameters saved -.");

      // Trigger historical return calc
      this.onCalculateReturnsClick();
    })
  }

   afterOptimizationDateReceived() {
      this.initTabels();

    
 //   this.fetchEvaluationData()
 //    this.initTabels();
    
    this.initBlacklitterMatrix();

    this.optimizationData.correlation_matrix.items = this.optimizationData.correlation_matrix.items.map((i: any) => {


      i.Var1 = this.getFundName(i.Var1, false);
      i.Var2 = this.getFundName(i.Var2, false);

      return i;
    });

    this.correlationMatrixTableData = this.extractHeatMapTableData(this.optimizationData.correlation_matrix.items, Math.sqrt(this.optimizationData.correlation_matrix.items.length));

    this.optimizationData.covariance_matrix.items = this.optimizationData.covariance_matrix.items.map((i: any) => {


      i.Var1 = this.getFundName(i.Var1, false);
      i.Var2 = this.getFundName(i.Var2, false);
      if (!i.converted) {
        i.value = i.value * 100;
        i.converted = true;
      }
      return i;
    });
    this.covarianceMatrixTableData = this.extractHeatMapTableData(this.optimizationData.covariance_matrix.items, Math.sqrt(this.optimizationData.covariance_matrix.items.length));

    
    this.initEfficientChart();
    this.initViewTables();
  //  this.fetchOptimizationData();
    setTimeout(() => {this.dataLoaded = true;}, 1000);
  }

  async fetchEvaluationData(){
    this.evaluationData = await this.service.fetchEvaluationData(this.portfolio.id);
      if(this.evaluationDataLoaded)
        this.setGeneralData();
      this.evaluationDataLoaded = true;
  }

  initBlacklitterMatrix() {
    if (this.optimizationData.covariance_matrix_blacklitter && this.optimizationData.covariance_matrix_blacklitter.items) {
      this.optimizationData.covariance_matrix_blacklitter.items = this.optimizationData.covariance_matrix_blacklitter.items.map((i: any) => {
        if (!i.converted) {
          i.value = i.value * 100;
          i.converted = true;
        }
        return i;
      })
      this.covarianceMatrixBlacklitterTableData = this.extractHeatMapTableData(this.optimizationData.covariance_matrix_blacklitter.items, this.portfolio.securities.length);
    }
  }

  initGroupConstraintLists() {
    let sectors = [];
    let countries = [];
    if (this.portfolio && this.portfolio.id && this.portfolio.securities) {
      this.portfolio.securities.map((c: CompanyModel) => {
        if(c.security_type_equity === 'SHARE'){
          if (c.sector) {
            sectors.push(c.sector)
          }
          if (c.country) {
            countries.push(c.country)
          }
        }
      })
      this.constraintLists.sectors = this.util.keepUniqueObjectOfArray(sectors);
      this.constraintLists.countries = this.util.keepUniqueObjectOfArray(countries);
      this.constraintLists.assets = this.portfolio.securities.map((s: any) => {
        s.name = s.company_name;
        return s;
      });
      this.constraintLists.assets.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
    }

    if (this.portfolio && this.portfolio.id && this.portfolio.funds) {
      this.portfolio.securities.map((c: CompanyModel) => {
        // if (c.sector) {
        //   sectors.push({'id': 23, 'name': 'MutualFund'})
        // }
        // if (c.country) {
        //   countries.push({'id': 161, 'name': 'MutualFund'})
        // }
      })
       this.constraintLists.sectors = this.util.keepUniqueObjectOfArray(sectors);
       this.constraintLists.countries = this.util.keepUniqueObjectOfArray(countries);
     
      this.constraintLists.assets.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
    }
  }

  initEfficientChart() {

    this.efficientSeries = [];
    const lineData = [];
    const std_dev = [];
    const unconstrainedLineData = [];
    let scatterData = [];
    let optimScatterData = [];
    this.efficientOptimWeights = [];

    if (this.optimizationData && this.optimizationData.optim_weights_efficient_frontier) {
      let weights: any = {};
      this.optimizationData.optim_weights_efficient_frontier.map((data: any) => {
        if (data.view_name === 'forecasted_returns') {
          if (!weights[data.variable]) {
            weights[data.variable] = {
              Ticker: data.variable,
              company_name: this.portfolio.security_overviews[data.variable] ? this.portfolio.security_overviews[data.variable].company_name : this.getFundName(data.variable),
              id_w: [],
              weight: 0,
              vol : std_dev
            }
          }
          weights[data.variable].id_w.push(+(data.value*100).toFixed(2));
        }
      })
      this.efficientOptimWeights = Object.values(weights);
    }

    if (this.portfolio.efficient_frontier) {
      if (this.portfolio.efficient_frontier[this.efficientFrontierType]) {
        let index = 0;
        this.portfolio.efficient_frontier[this.efficientFrontierType].map((d: any) => {
          if (d.view_name === 'forecasted_returns') {
            lineData.push({
              x: +(d.sd_ret*100).toFixed(2),
              y: +(d.exp_ret*100).toFixed(2),
              return_index: index,
              sharpeRatio: +(d.sharpe*100).toFixed(2),
            })
            std_dev.push(+(d.sd_ret*100).toFixed(2))
            index++;
          }
        })
        this.minVolatility = lineData[0].x;
        this.maxVolatility = lineData[99].x
      }

      if (this.portfolio.efficient_frontier[EfficientType.Unconstrainted]) {
        this.portfolio.efficient_frontier[EfficientType.Unconstrainted].map((d: any) => {
          if (d.view_name === 'forecasted_returns') {
            unconstrainedLineData.push({
              x: +(d.sd_ret*100).toFixed(2),
              y: +(d.exp_ret*100).toFixed(2),
            });
          }
        });
      }

      scatterData = this.extractEfficientFrontierScatterData();
      optimScatterData = this.extractEfficientFrontierScatterData(true);
    }

    const lineSeries = {
      type: 'line',
      name: 'Optim Efficient Frontier',
      data: lineData,
      zIndex: 3
    }

    const scatterSeries = {
      type: 'scatter',
      name: 'Stocks',
      marker: {
        radius: 4,
        symbol: 'circle'
      },
      data: scatterData,
      zIndex: 10,
      enableMouseTracking: true
    }

    const optimScatterSeries = {
      type: 'scatter',
      name: 'Optims',
      marker: {
        radius: 4,
        symbol: 'circle'
      },
      data: optimScatterData,
      zIndex: 10,
      enableMouseTracking: true
    }

    const unconstrainedLineSeries = {
      type: 'line',
      name: '',
      data: unconstrainedLineData,
      zIndex: 1,
      dashStyle: 'Dash',
      enableMouseTracking: false,
      visible: false
    };

    this.efficientSeries.push(lineSeries);
    this.efficientSeries.push(scatterSeries);
    this.efficientSeries.push(unconstrainedLineSeries);
    this.efficientSeries.push(optimScatterSeries);

    this.resetScatterData( );
    // Collect optim weights from optim_weights_efficient_frontier
    
  }

  


  updatePageWithBenchmark() {
    this.efficientChartLoaded = false;
    this.efficientSeries.pop(); // remove scatter data, last element
    this.resetScatterData();
  }

  getCorrelationMatrix() {
    this.service.getCorrelationMatrix(this.portfolio.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      resp.items = resp.items.map((data: any) => { 

         data.Var1 = this.getFundName(data.Var1);
         return data;
       });

      this.correlationMatrixData = this.extractHeatMapData(resp.items)
    })
  }

  getCovarianceMatrix() {
    this.service.getCovarianceMatrix(this.portfolio.id).subscribe((resp: any) => {
      if (resp.error) {
        return this.service.notificationService.open(resp.error)
      }
      if (resp.items) {
        resp.items = resp.items.map((i: any) => {
          i.Var1 = this.getFundName(i.Var1);
          if (!i.converted) {
            i.value = i.value * 100;
            i.converted = true;
          }
          return i;
        })
      }
      this.covarianceMatrixData = this.extractHeatMapData(resp.items);
    })
  }

  saveCorrelation() {
    const params = {
      frequency: this.optimizationData.optim_parameters.correlation_frequency,
      method: this.optimizationData.optim_parameters.correlation_method
    }
    this.service.saveCovmatParams(this.portfolio.id, params).subscribe((resp: any) => {
      if (resp.errro) {
        this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open("Correlation parameters saved.");
      this.openWebsocket();
    })
  }

  onReturnTypeChange() {
    this.dataLoaded = false;
    this.initEfficientChart();
    this.initTabels();
  }

  initTabels() {
    this.optimPerformance = this.portfolio.optim_performance.filter(d => d.view_name === 'forecasted_returns');
    this.optimWeights = this.portfolio.optim_weights.filter(d => d.view_name === 'forecasted_returns');
    this.filteredOptimWeights = [...this.optimWeights];
    setTimeout(() => {this.dataLoaded = true;}, 100);
  }

  onOptimTypeChange() {
    let prop = 'optim_performance';
    switch (this.selectedPerformanceType) {
      case 'unconstrained':
        prop += '_unconstrained';
        break;
      case 'cost_of_constrained':
        prop += '_cost_of_constrained';
        break;
    }
    this.optimPerformance = this.portfolio[prop].filter(d => d.view_name === 'forecasted_returns');
    this.optimPerformance = this.optimPerformance.map(p => {
      p.name = this.service.convertEvaluationType(p.Portfolio.split('_').join(' '));
      return p;
    });
  }

  onCalculateReturnsClick() {

    if (
      !this.optimizationData.optim_parameters.return_start_date ||
      !this.optimizationData.optim_parameters.return_end_date
    ) {
      return this.service.notificationService.open('Fill the start and end dates.')
    }
    this.service.saveItem(this.portfolio, false).subscribe((resp: any) => {
      if (resp.id) {
        this.loadingService.isLoading.next(true)
        // Wait for R script to answer
        
        this.service.notificationService.open("Portfolio saved. Page will reload automatically");
        this.openWebsocket();



        // User came from portfolio overview page, redirect him back
        // if (this.portfolio && this.portfolio.id && this.portfolio.id == resp.id) {
        //   this.router.navigate(['/app/portfolio-view/'+resp.id])
        // } else {
        //   this.router.navigate(['/app'])
        // }
      }
    });
    // this.service.calculateReturnData(this.portfolio.id).subscribe((resp: any) => {
    //   this.service.notificationService.open('Calculation started');
    //   this.openWebsocket();
    // })
  }

  collectParamsForPost() {
    let optimParams: any = {
      optim_parameters: this.optimizationData.optim_parameters
    }

    let assumptions = [];
    this.optimizationData.forecasted_returns.map((ass:any) => {
      assumptions.push({
        forecasted_returns:  ass.forecasted_returns,  // divide with 100 to save proper data in db
        Ticker: ass.Ticker
      });
    })
    optimParams.optim_parameters.optim_performance = this.optim.toString();
    optimParams.optim_parameters.assumptions = assumptions;
    return optimParams
  }

  onOptimizeClick(saveCorrelation = false, saveInputsOnly = false) {
    let optimParams: any = this.collectParamsForPost();

    // Trigger optimization R script if we click the optimize button (saveCorrelation will be true if reload correlation clicked)
    if (!saveCorrelation) {
      optimParams.action = 'optimize' // trigger calculation
    }
    this.service.saveOptimizationData(this.portfolio.id, optimParams).subscribe((resp: any) => {
      if (resp.errro) {
        this.service.notificationService.open(resp.error)
      }
      this.service.notificationService.open("Optimization parameters saved + .");
      if (!saveInputsOnly) {
        if (saveCorrelation) {
          this.saveCorrelation();
        } else {
          this.openWebsocket();
        }
      }
    })
  }

  onFilterChange() {
    this.viewTablesLoaded = false;
    if (this.filter.type && this.filter.type.length) {
      this.filteredOptimWeights = [...this.optimWeights].filter(d => this.filter.type.includes(this.portfolio.security_overviews[d.Ticker].security_type_equity));
      this.filteredHistoricalReturnTableData = [...this.historicalReturnTableData].filter(d => this.filter.type.includes(this.portfolio.security_overviews[d.Ticker].security_type_equity));
      this.filteredMarketEquilibriumTableData = [...this.marketEquilibriumTableData].filter(d => this.filter.type.includes(this.portfolio.security_overviews[d.Ticker].security_type_equity));
      this.filteredImpliedReturnData = [...this.impliedReturnData].filter(d => this.filter.type.includes(this.portfolio.security_overviews[d.Ticker].security_type_equity));
    } else {
      this.filteredOptimWeights = [...this.optimWeights];
      this.filteredHistoricalReturnTableData = [...this.historicalReturnTableData];
      this.filteredMarketEquilibriumTableData = [...this.marketEquilibriumTableData];
      this.filteredImpliedReturnData = [...this.impliedReturnData];
    }
    this.filteredForecastedReturnsTableData = this.forecastedReturnsTableData.map((e) => {
      return {
        ...e,
        mkt_eq_returns: (e.implied_returns - e.market_equilibrium).toPrecision(4),
      }
    }).filter((d: any) => {
      let sectorFound = true;
      let countryFound = true;
      let typeFound = true;

      if (this.filter.sector && this.filter.sector.length) {
        sectorFound = this.filter.sector.includes(d.sector);
      }

      if (this.filter.country && this.filter.country.length) {
        countryFound = this.filter.country.includes(d.country);
      }

      if (this.filter.type && this.filter.type.length) {
        typeFound = this.filter.type.includes(d.type);
      }

      if (sectorFound && countryFound && typeFound) {
        return d;
      }
    })
    setTimeout(() => {this.viewTablesLoaded = true}, 100);
  }

  onOptimizedWeightSaved() {
    this.openWebsocket();
  }

  triggerCalc(type: string) {
    const minValue = new Date();
    switch(type) {
      case 'ytd':
        minValue.setMonth(0);
        minValue.setDate(1);
        this.optimizationData.optim_parameters.return_start_date = minValue.toISOString().split('T')[0];
        break;
      case '1':
        minValue.setFullYear(minValue.getFullYear() - 1);
        this.optimizationData.optim_parameters.return_start_date = minValue.toISOString().split('T')[0];
        break;
      case '5':
        minValue.setFullYear(minValue.getFullYear() - 5);
        this.optimizationData.optim_parameters.return_start_date = minValue.toISOString().split('T')[0];
        break;
        case '10':
          minValue.setFullYear(minValue.getFullYear() - 10);
          this.optimizationData.optim_parameters.return_start_date = minValue.toISOString().split('T')[0];
          break;
        case '15':
          minValue.setFullYear(minValue.getFullYear() - 15);
          this.optimizationData.optim_parameters.return_start_date = minValue.toISOString().split('T')[0];
          break;
      case 'start':
        this.optimizationData.optim_parameters.return_start_date = this.portfolio.start_date;
        break;
      default:
        break;
    }
    this.optimizationData.optim_parameters.return_end_date = (new Date()).toISOString().split('T')[0];
  }

  getConstraintForAsset(asset: string) {
    let result = null;
    this.optimizationData.group_constraints.forEach(function (constraint) {
      if (constraint.type.id === 'asset' && constraint.items[0].name === asset) {
        result = {
          min: constraint.min / 100,
          max: constraint.max / 100
        }
      }
    })

    return result;
  }

  handleCalculate(event: any) {

    this.optimizationData.optim_parameters.efficient_target_return = event.return;
    this.optimizationData.optim_parameters.efficient_target_risk = event.risk;

    this.onOptimizeClick();
  }
}
